<template>
  <div class="steps">
    <a-steps :current="current">
      <a-step v-for="item in steps" :key="item.title" :title="item.title" />
    </a-steps>
    <div class="steps-content">
      <div class="basic" v-if="steps[current].slug == 'basic'">
        <a-form ref="courseFormRef" :model="courseForm" :rules="courseRules" layout="vertical"
          :wrapper-col="{ span: 10, offset: 6 }"
          :label-col="{ span: 10, offset: 6 }"
        >
          <a-form-item label="Title" required has-feedback name="title">
            <a-input v-model:value="courseForm.title" size="large" type="text" placeholder="Enter title" allow-clear autocomplete="off"/>
          </a-form-item>
          <a-form-item label="Category" required has-feedback name="category">
            <a-select v-model:value="courseForm.category" size="large" placeholder="Select category">
              <a-select-option :value="null">Select One</a-select-option>
              <a-select-option :value="category._id" v-for="category in categories" :key="category._id">{{category.title}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Description" has-feedback name="description">
            <a-textarea v-model:value="courseForm.description" />
          </a-form-item>
          <a-form-item label="Course price (£)" has-feedback name="price">
            <a-input type="number" size="large" v-model:value="courseForm.price" allow-clear autocomplete="off"/>
          </a-form-item>
          <a-form-item label="Discount price (£)" has-feedback name="discount">
            <a-input type="number" size="large" v-model:value="courseForm.discount" allow-clear autocomplete="off"/>
          </a-form-item>
          <a-form-item label="Course Type" has-feedback name="type">
            <a-select size="large" v-model:value="courseForm.type" placeholder="Select course type" :disabled="disableCourseType">
              <a-select-option value="Single">Single</a-select-option>
              <a-select-option value="Group">Group</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Course Validity" has-feedback name="validity">
            <a-select size="large" v-model:value="courseForm.validity" placeholder="Select course validity">
              <a-select-option value=0>Not Applicable</a-select-option>
              <a-select-option value=6>6 Months</a-select-option>
              <a-select-option value=12>12 Months</a-select-option>
              <a-select-option value=18>18 Months</a-select-option>
              <a-select-option value=24>24 Months</a-select-option>
              <a-select-option value=30>30 Months</a-select-option>
              <a-select-option value=36>36 Months</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Assessor Name" required has-feedback name="assessor">
            <a-input v-model:value="courseForm.assessor" size="large" type="text" placeholder="Enter assessor name" allow-clear autocomplete="off"/>
          </a-form-item>
          <a-form-item label="Image" name="image">
            <a-upload :headers="headers" :action="imageUploadUrl" list-type="picture-card" :file-list="fileList" @preview="handlePreview" @change="handleChange" v-model:value="courseForm.image">
              <div v-if="fileList.length <= 0">
                <PictureOutlined />
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-form>
      </div>
      <div class="out-comes" v-if="steps[current].slug == 'out-comes'">
        <a-form ref="outcomeFormRef" :model="outcomeForm" v-bind="formItemLayoutWithOutLabel">
          <a-form-item v-for="(outcome, index) in outcomeForm.outcome" :key="outcome.key" v-bind="index === 0 ? formItemLayout : {}" :label="index === 0 ? 'Outcome' : ''"
            :name="['outcome', index, 'value']"
            :rules="{
              required: true,
              message: 'Outcome is required',
              trigger: 'blur',
            }"
          >
            <a-input size="large" v-model:value="outcome.value" placeholder="Please input outcome"/>
            <a-tooltip v-if="outcomeForm.outcome.length > 1 && outcomeForm.outcome.length - 1 != index">
              <template #title>
                <span>Remove</span>
              </template>
              <MinusCircleOutlined
              :disabled="outcomeForm.outcome.length === 1"
              @click="removeOutcome(outcome)"
              :style="{ fontSize: '20px', color: '#999999' }"
            />
            </a-tooltip>
            <a-tooltip v-if="outcomeForm.outcome.length == 1 || outcomeForm.outcome.length - 1 == index">
              <template #title>
                <span>Add</span>
              </template>
            <PlusCircleOutlined
              :disabled="outcomeForm.outcome.length === 1"
              @click="addOutcome"
              :style="{ fontSize: '20px', color: '#72cf97' }"
            />
            </a-tooltip>
          </a-form-item>
        </a-form>
      </div>
      <div class="finish" v-if="steps[current].slug == 'finish'">
        <div class="icon">
          <HeartOutlined :style="{ fontSize: '25px', color: '#ff7875' }" />
        </div>
        <div class="title">Thank You!</div>
        <div class="message">You course has been {{editCourse ? 'updated' : 'created'}} successfully!</div>
        <div class="action">
          <a-button type="primary"><router-link to="/course"><UnorderedListOutlined /> Course</router-link></a-button>
        </div>
      </div>
      <div class="steps-action">
        <a-button type="danger" shape="circle" size="large" v-if="current > 0 && current < steps.length - 1" @click="prev">
          <ArrowLeftOutlined/>
        </a-button>
        <a-button type="danger" shape="circle" size="large" style="margin-left: 8px" v-if="current < steps.length - 1" @click="next" :loading="loading">
          <ArrowRightOutlined/>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, onMounted, reactive, ref, UnwrapRef } from 'vue'
import { notification } from 'ant-design-vue'
import { PictureOutlined, MinusCircleOutlined, PlusCircleOutlined, UnorderedListOutlined, ArrowLeftOutlined, ArrowRightOutlined, HeartOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'

import categoryService from '../../../services/category'
import courseService from '../../../services/course'
import commonServices from '../../../services/common'

import validate from '../../../services/validator'

import ICourse from '../../../interface/course'

function getBase64 (file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
interface Outcome {
  value: string;
  key?: number;
  _id?: string;
}
interface OutcomeFormState {
  course: string;
  outcome: Array<Outcome>;
}
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}
interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
export default defineComponent({
  components: {
    PictureOutlined,
    UnorderedListOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    HeartOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined
  },
  props: ['editCourse'],
  setup (props) {
    const route = useRoute()
    const courseId = ref<string>(route.params.id as string)
    const outcomeFormRef = ref()
    const formItemLayout = {
      labelCol: {
        xs: { span: 14 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 14 },
        sm: { span: 12 }
      }
    }
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 14, offset: 0 },
        sm: { span: 12, offset: 6 }
      }
    }
    const outcomeForm: UnwrapRef<OutcomeFormState> = reactive({
      course: '',
      outcome: [{
        value: '',
        key: Date.now()
      }]
    })

    const imageUploadUrl = ref<string>(process.env.VUE_APP_API_URL + process.env.VUE_APP_FILE_UPLOAD)
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const categories = ref<Array<{ _id: string; title: string; description: string; image: string; parent_id: string }>>([])
    const current = ref<number>(0)
    const disableCourseType = ref<boolean>(false)
    const course = ref<ICourse>()
    const courseOutcome = ref<Array<Outcome>>([])
    const childCourse = ref<Array<ICourse>>([])
    const steps = [
      {
        title: 'Basic',
        slug: 'basic'
      },
      {
        title: 'Out Comes',
        slug: 'out-comes'
      },
      {
        title: 'Finish',
        slug: 'finish'
      }
    ]
    const courseFormRef = ref()
    const courseRules = validate.courseValidation
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const courseForm: UnwrapRef<ICourse> = reactive({
      title: '',
      category: null,
      description: '',
      price: null,
      discount: 0,
      provider: '',
      validity: 0,
      url: '',
      assessor: '',
      image: '',
      type: '',
      published: false,
      // eslint-disable-next-line @typescript-eslint/camelcase
      parent_id: ''
    })
    const loading = ref<boolean>(false)
    const previewVisible = ref<boolean>(false)
    const previewImage = ref<string | undefined>('')
    const fileList = ref<FileItem[]>([])
    const createCourse = async (values: ICourse) => {
      values.price = +values.price
      values.discount = +values.discount
      values.validity = +values.validity
      if (courseId.value) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.parent_id = courseId.value
      }
      loading.value = true
      try {
        const responce = await courseService.createCourse(values)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          course.value = responce.data
          current.value++
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const updateCourse = async (values: ICourse) => {
      values.price = +values.price
      values.discount = +values.discount
      values.validity = +values.validity
      loading.value = true
      try {
        const responce = await courseService.updateCourse(values, courseId.value)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          course.value = responce.data
          current.value++
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const getAllChildCourse = async (parentId) => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await courseService.getChildCourses(parentId, profile._id)
        childCourse.value = responce.data
        if (childCourse.value.length > 0) {
          disableCourseType.value = true
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getCourseOutcomes = async (courseId) => {
      try {
        const responce = await courseService.getCourseOutcome(courseId)
        courseOutcome.value = responce.data
        if (props.editCourse) {
          outcomeForm.course = courseId
          outcomeForm.outcome = []
          for (const outcome of responce.data) {
            outcomeForm.outcome.push({ value: outcome.title, _id: outcome._id, key: Date.now() })
          }
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getCourseDetails = async (courseId) => {
      const responce = await courseService.getCourseDetails(courseId)
      course.value = responce.data
      const c = responce.data
      c.validity = '' + responce.data.validity
      if (props.editCourse) {
        Object.assign(courseForm, c)
        if (courseForm.image) {
          fileList.value.push(
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: courseForm.image
            }
          )
        }
      }
    }
    const saveOutcome = async (values: OutcomeFormState) => {
      loading.value = true
      try {
        for (const cOutcome of values.outcome) {
          const c = course.value._id
          const value = cOutcome.value
          await courseService.addCourseOutcome({ course: c, title: value })
        }
        loading.value = false
        current.value++
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const deleteOutcome = async (values: Outcome) => {
      try {
        await courseService.deleteOutcome(values._id)
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const updateOutcome = async (values: OutcomeFormState) => {
      loading.value = true
      try {
        for (const cOutcome of values.outcome) {
          const c = course.value._id
          const value = cOutcome.value
          if (cOutcome._id) {
            await courseService.updateCourseOutcome({ course: c, title: value }, cOutcome._id) // Update existing
          } else { // Create newly added
            await courseService.addCourseOutcome({ course: c, title: value })
          }
        }
        loading.value = false
        current.value++
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const next = () => {
      if (current.value === 0) {
        courseFormRef.value
          .validate()
          .then(() => {
            if (props.editCourse) {
              updateCourse(courseForm)
            } else {
              createCourse(courseForm)
            }
          })
          .catch((error: ValidateErrorEntity<ICourse>) => {
            console.log('error', error)
          })
      }
      if (current.value === 1) {
        outcomeFormRef.value
          .validate()
          .then(() => {
            if (props.editCourse) {
              updateOutcome(outcomeForm)
            } else {
              saveOutcome(outcomeForm)
            }
          })
          .catch((error: ValidateErrorEntity<OutcomeFormState>) => {
            console.log('error', error)
          })
      }
    }
    const prev = () => {
      current.value--
    }
    const removeOutcome = (item) => {
      const index = outcomeForm.outcome.indexOf(item)
      if (index !== -1) {
        outcomeForm.outcome.splice(index, 1)
      }
      if (props.editCourse) {
        deleteOutcome(item)
      }
    }
    const addOutcome = () => {
      outcomeForm.outcome.push({
        value: '',
        key: Date.now()
      })
    }
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const handleChange = ({ fileList: newFileList }: FileInfo) => {
      if (fileList.value) {
        const s3Response: any = fileList.value
        fileList.value = newFileList
        if (s3Response.length > 0 && s3Response[0].response) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          courseForm.image = s3Response[0].response.Location
        }
      }
    }
    const getParentCategories = async () => {
      try {
        const res = await categoryService.getCategory()
        categories.value = res.data
      } catch (error) {
        console.log(error)
        notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getParentCategories()
      if (props.editCourse) {
        getCourseDetails(courseId.value)
        getAllChildCourse(courseId.value)
        getCourseOutcomes(courseId.value)
      }
    })
    return {
      loading,
      handleChange,
      previewVisible,
      previewImage,
      handlePreview,
      handleCancel,
      courseForm,
      saveOutcome,
      addOutcome,
      removeOutcome,
      next,
      prev,
      fileList,
      courseRules,
      steps,
      courseFormRef,
      notify,
      current,
      categories,
      imageUploadUrl,
      headers,
      course,
      outcomeFormRef,
      formItemLayout,
      formItemLayoutWithOutLabel,
      outcomeForm,
      courseId,
      disableCourseType,
      courseOutcome
    }
  }
})
</script>

<style lang="scss">
.course-add {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .back {
      float: right;
      .ant-btn {
        border-color: #7EAF1A;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        color: #7EAF1A;
        a {
          color: #7EAF1A;
        }
      }
    }
  }
  .body {
    padding: 10px;
    .steps {
      min-height: 100px;
      padding-top: 20px;
      width: 100%;
      .ant-steps {
        background: #ffffff;
        border-radius: 10px;
        padding: 20px 100px;
        .ant-steps-item {
          margin-right: 0;
          text-align: left;
          .ant-steps-item-icon {
            display: none;
          }
          .ant-steps-item-title {
            border: 1px solid #7EAF1A;
            border-radius: 20px;
            width: 100px;
            font-size: 14px;
            font-family: "TT Norms Pro Medium";
            text-align: center;
            padding-right: 0;
          }
        }
        .ant-steps-item-wait {
          .ant-steps-item-title {
            color: #7EAF1A;
          }
        }
        .ant-steps-item-finish,
        .ant-steps-item-process {
          .ant-steps-item-title {
            background: #7EAF1A;
            color: #ffffff;
          }
        }
        .ant-steps-item-finish {
          .ant-steps-item-title::after {
            background-color: #7EAF1A;
          }
        }
      }
      .steps-content {
        margin-top: 16px;
        background: #ffffff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        min-height: 275px;
        text-align: center;
        padding-top: 20px;
        .basic {
          .ant-form-vertical {
            .ant-select {
              text-align: left;
            }
            .ant-col-offset-6 {
              margin-left: 25% !important;
            }
            .ant-form-item {
              padding-bottom: 0 !important;
            }
          }
          .ant-upload-picture-card-wrapper {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            .ant-upload-select-picture-card {
              margin-bottom: 0;
              background-color: #d9d9d9;
              border: none;
              i {
                font-size: 25px;
              }
            }
          }
        }
        .out-comes {
          .ant-form-item-control {
            text-align: left;
            input {
              width: 90%;
              margin-right: 10px;
            }
          }
          .dynamic-delete-button {
            cursor: pointer;
            position: relative;
            top: 4px;
            font-size: 24px;
            transition: all 0.3s;
          }
          .dynamic-add-button {
            cursor: pointer;
            position: relative;
            top: 4px;
            font-size: 24px;
            transition: all 0.3s;
          }
          .dynamic-delete-button:hover {
            color: #777;
          }
          .dynamic-delete-button[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
      .finish {
        .icon {
          padding-top: 40px;
          i {
            font-size: 30px;
            color: #ff4d4f;
          }
        }
        .title {
          font-size: 20px;
          padding: 13px;
          font-family: "TT Norms Pro Bold";
        }
        .message {
          padding: 0 0 10px;
        }
        .action {
          button {
            background: #7EAF1A;
            border: 1px solid #7EAF1A;
            border-radius: 20px;
            width: 100px;
          }
        }
      }
      .steps-action {
        background: #ffffff;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-bottom: 20px;
      }
    }
    .ant-form-explain {
      text-align: left;
    }
  }
}
</style>
