
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, onMounted, reactive, ref, UnwrapRef } from 'vue'
import { notification } from 'ant-design-vue'
import { PictureOutlined, MinusCircleOutlined, PlusCircleOutlined, UnorderedListOutlined, ArrowLeftOutlined, ArrowRightOutlined, HeartOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'

import categoryService from '../../../services/category'
import courseService from '../../../services/course'
import commonServices from '../../../services/common'

import validate from '../../../services/validator'

import ICourse from '../../../interface/course'

function getBase64 (file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
interface Outcome {
  value: string;
  key?: number;
  _id?: string;
}
interface OutcomeFormState {
  course: string;
  outcome: Array<Outcome>;
}
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}
interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
export default defineComponent({
  components: {
    PictureOutlined,
    UnorderedListOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    HeartOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined
  },
  props: ['editCourse'],
  setup (props) {
    const route = useRoute()
    const courseId = ref<string>(route.params.id as string)
    const outcomeFormRef = ref()
    const formItemLayout = {
      labelCol: {
        xs: { span: 14 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 14 },
        sm: { span: 12 }
      }
    }
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 14, offset: 0 },
        sm: { span: 12, offset: 6 }
      }
    }
    const outcomeForm: UnwrapRef<OutcomeFormState> = reactive({
      course: '',
      outcome: [{
        value: '',
        key: Date.now()
      }]
    })

    const imageUploadUrl = ref<string>(process.env.VUE_APP_API_URL + process.env.VUE_APP_FILE_UPLOAD)
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const categories = ref<Array<{ _id: string; title: string; description: string; image: string; parent_id: string }>>([])
    const current = ref<number>(0)
    const disableCourseType = ref<boolean>(false)
    const course = ref<ICourse>()
    const courseOutcome = ref<Array<Outcome>>([])
    const childCourse = ref<Array<ICourse>>([])
    const steps = [
      {
        title: 'Basic',
        slug: 'basic'
      },
      {
        title: 'Out Comes',
        slug: 'out-comes'
      },
      {
        title: 'Finish',
        slug: 'finish'
      }
    ]
    const courseFormRef = ref()
    const courseRules = validate.courseValidation
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const courseForm: UnwrapRef<ICourse> = reactive({
      title: '',
      category: null,
      description: '',
      price: null,
      discount: 0,
      provider: '',
      validity: 0,
      url: '',
      assessor: '',
      image: '',
      type: '',
      published: false,
      // eslint-disable-next-line @typescript-eslint/camelcase
      parent_id: ''
    })
    const loading = ref<boolean>(false)
    const previewVisible = ref<boolean>(false)
    const previewImage = ref<string | undefined>('')
    const fileList = ref<FileItem[]>([])
    const createCourse = async (values: ICourse) => {
      values.price = +values.price
      values.discount = +values.discount
      values.validity = +values.validity
      if (courseId.value) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.parent_id = courseId.value
      }
      loading.value = true
      try {
        const responce = await courseService.createCourse(values)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          course.value = responce.data
          current.value++
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const updateCourse = async (values: ICourse) => {
      values.price = +values.price
      values.discount = +values.discount
      values.validity = +values.validity
      loading.value = true
      try {
        const responce = await courseService.updateCourse(values, courseId.value)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          course.value = responce.data
          current.value++
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const getAllChildCourse = async (parentId) => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await courseService.getChildCourses(parentId, profile._id)
        childCourse.value = responce.data
        if (childCourse.value.length > 0) {
          disableCourseType.value = true
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getCourseOutcomes = async (courseId) => {
      try {
        const responce = await courseService.getCourseOutcome(courseId)
        courseOutcome.value = responce.data
        if (props.editCourse) {
          outcomeForm.course = courseId
          outcomeForm.outcome = []
          for (const outcome of responce.data) {
            outcomeForm.outcome.push({ value: outcome.title, _id: outcome._id, key: Date.now() })
          }
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getCourseDetails = async (courseId) => {
      const responce = await courseService.getCourseDetails(courseId)
      course.value = responce.data
      const c = responce.data
      c.validity = '' + responce.data.validity
      if (props.editCourse) {
        Object.assign(courseForm, c)
        if (courseForm.image) {
          fileList.value.push(
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: courseForm.image
            }
          )
        }
      }
    }
    const saveOutcome = async (values: OutcomeFormState) => {
      loading.value = true
      try {
        for (const cOutcome of values.outcome) {
          const c = course.value._id
          const value = cOutcome.value
          await courseService.addCourseOutcome({ course: c, title: value })
        }
        loading.value = false
        current.value++
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const deleteOutcome = async (values: Outcome) => {
      try {
        await courseService.deleteOutcome(values._id)
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const updateOutcome = async (values: OutcomeFormState) => {
      loading.value = true
      try {
        for (const cOutcome of values.outcome) {
          const c = course.value._id
          const value = cOutcome.value
          if (cOutcome._id) {
            await courseService.updateCourseOutcome({ course: c, title: value }, cOutcome._id) // Update existing
          } else { // Create newly added
            await courseService.addCourseOutcome({ course: c, title: value })
          }
        }
        loading.value = false
        current.value++
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const next = () => {
      if (current.value === 0) {
        courseFormRef.value
          .validate()
          .then(() => {
            if (props.editCourse) {
              updateCourse(courseForm)
            } else {
              createCourse(courseForm)
            }
          })
          .catch((error: ValidateErrorEntity<ICourse>) => {
            console.log('error', error)
          })
      }
      if (current.value === 1) {
        outcomeFormRef.value
          .validate()
          .then(() => {
            if (props.editCourse) {
              updateOutcome(outcomeForm)
            } else {
              saveOutcome(outcomeForm)
            }
          })
          .catch((error: ValidateErrorEntity<OutcomeFormState>) => {
            console.log('error', error)
          })
      }
    }
    const prev = () => {
      current.value--
    }
    const removeOutcome = (item) => {
      const index = outcomeForm.outcome.indexOf(item)
      if (index !== -1) {
        outcomeForm.outcome.splice(index, 1)
      }
      if (props.editCourse) {
        deleteOutcome(item)
      }
    }
    const addOutcome = () => {
      outcomeForm.outcome.push({
        value: '',
        key: Date.now()
      })
    }
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const handleChange = ({ fileList: newFileList }: FileInfo) => {
      if (fileList.value) {
        const s3Response: any = fileList.value
        fileList.value = newFileList
        if (s3Response.length > 0 && s3Response[0].response) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          courseForm.image = s3Response[0].response.Location
        }
      }
    }
    const getParentCategories = async () => {
      try {
        const res = await categoryService.getCategory()
        categories.value = res.data
      } catch (error) {
        console.log(error)
        notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getParentCategories()
      if (props.editCourse) {
        getCourseDetails(courseId.value)
        getAllChildCourse(courseId.value)
        getCourseOutcomes(courseId.value)
      }
    })
    return {
      loading,
      handleChange,
      previewVisible,
      previewImage,
      handlePreview,
      handleCancel,
      courseForm,
      saveOutcome,
      addOutcome,
      removeOutcome,
      next,
      prev,
      fileList,
      courseRules,
      steps,
      courseFormRef,
      notify,
      current,
      categories,
      imageUploadUrl,
      headers,
      course,
      outcomeFormRef,
      formItemLayout,
      formItemLayoutWithOutLabel,
      outcomeForm,
      courseId,
      disableCourseType,
      courseOutcome
    }
  }
})
